import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import GhostContentAPI from '@tryghost/content-api';
import {Link} from 'react-router-dom';

import LoadingIndicator from '../Common/LoadingIndicator/LoadingIndicator';

const ghost = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_URL,
  key: process.env.REACT_APP_GHOST_KEY,
  version: 'v5'
});

function BlogCategoryListCard(props) {
  const {className, selected} = props;

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    ghost.tags.browse({limit: 18, filter: 'visibility:public', include: 'count.posts', order: 'count.posts DESC'})
      .then(result => setCategories(result))
      .catch(() => setCategories([]))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={className}>
      <div className="card">
        <div className="card-body">

          <h3 className="h5 card-title">
            Categories
          </h3>

          <div className="card-text">
            <LoadingIndicator className="my-5" loading={loading} dimension={50}/>

            <div className="row">
              {categories.map(category => (
                <div key={category.name} className="col-md-6 pb-1 text-nowrap text-truncate text-capitalize">
                  <Link
                    to={`/blog?category=${category.name}`}
                    className={category.name === selected ? 'font-weight-bold' : ''}>
                    {category.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

BlogCategoryListCard.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string
};

export default BlogCategoryListCard;
