import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import AboutBio from '../../components/About/AboutBio';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import AboutHero from '../../assets/images/heros/about-us-hero.png';
import RobertLill from '../../assets/images/headshots/Robert-Lill.jpg';
import MichaelArnett from '../../assets/images/headshots/Michael-Arnett.jpg';
import BnlTeam from '../../assets/images/misc/bnl-about-team.jpg';

import AmyArnett from '../../assets/images/headshots/Amy-Arnett.jpg';
import AustinV from '../../assets/images/headshots/Austin-V.jpg';
import NicoleG  from '../../assets/images/headshots/Nicole-G.jpg';
import MeaganE from '../../assets/images/headshots/Meagan-E.jpg';
import JoshF from '../../assets/images/headshots/Josh-F.jpg';
import RasheedJ from '../../assets/images/headshots/Rasheed-J.jpg';
import RobertT from '../../assets/images/headshots/Rob-T.jpg';
import BrimleyN from '../../assets/images/headshots/Brimley-N.jpg';
import CamillaN from '../../assets/images/headshots/Camilla-N.jpg';
import BrianR from '../../assets/images/headshots/Brian-R.jpg';
import SladeB from '../../assets/images/headshots/Slade-B.jpg';
import ThongN from '../../assets/images/headshots/Thong-N.jpg';
import NicoleB from '../../assets/images/headshots/Nicole-B.jpg';
import CarlieR from '../../assets/images/headshots/Carlie-R.jpg';
import DenysL from '../../assets/images/headshots/Denys-L.jpg';
import BradG from '../../assets/images/headshots/Brad-G.jpg';
import CourtneyH from '../../assets/images/headshots/Coutney-H.jpg';
import MaddieG from '../../assets/images/headshots/Maddie-G.jpg';

import NCMABadge from '../../assets/images/partners/NCMA_Badge.png';
import ELEVBadge from '../../assets/images/partners/ELEV_Badge.png';

export default function OurTeam() {

  return (
    <div className="About">

      <MetaData
        title="Meet Our Team"
        description="The team of BNL Consulting"
        image={AboutHero}
      />

      <Hero
        image={AboutHero}
        title="TEAM"
        detail="Get to know us"
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          From innovative developers to strategic thinkers, every member plays a vital role in delivering exceptional solutions to our clients. Meet the brilliant minds behind our projects and discover how their dedication drives innovation, fuels growth, and ensures we exceed expectations every step of the way.
        </p>
      </div>

      <div className="container">
        <hr className="my-5"/>
        <AboutBio
          image={NicoleG}
          name="Nicole"
          title=""
          bio1="Operations Administrator who thrives on streamlining daily operations and ensuring smooth workflows. In her free time, you'll find her exploring the world of culinary creations, with the goal of creating an amazing future cookbook full of delicious recipes."
          bio2=""
        />
        <hr className="my-5"/>
        <AboutBio
          image={MeaganE}
          name="Meagan"
          title=""
          bio1="Dedicated Operations Manager. Before her career at BNL Consulting, she was an adjunct college professor of psychology!"
          bio2=""
        />
        <hr className="my-5"/>
        <AboutBio
          image={JoshF}
          name="Josh"
          title=""
          bio1="A results-oriented Project Manager who ensures smooth collaboration within teams."
          bio2=""
        />
        <hr className="my-5"/>
        <AboutBio
          image={AmyArnett}
          name="Amy"
          title=""
          bio1="Amy is a passionate sales and business development professional with a proven track record of success. She was born in Asmara, Ethiopia, on a US Army base while her dad was there working on satellite communications during the Vietnam War."
          bio2=""
        />
        <hr className="my-5"/>
        <AboutBio
          image={AustinV}
          name="Austin"
          title=""
          bio1="A seasoned Web Accessibility Specialist & Front End Developer. His passion lies in the creative process of transforming ideas into functional web applications. Outside of work, he finds joy in flight simulation and aspires to obtain a private pilot's license in the future."
          bio2=""
        />
        <hr className="my-5"/>
        <AboutBio
          image={MichaelArnett}
          name="Mike"
          title=""
          bio1="Seasoned IT professional and the CEO at BNL Consulting. A die-hard Georgia Bulldogs fan and a proud finisher of an impressive 20-mile walk-a-thon at the young age of eight."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio

          name="Jason"
          title=""
          bio1="As the Director of Web Development, Jason finds it intrinsically rewarding to build the first layer of interaction between a user and the intricate world behind the scenes."
          bio2=""
        />
        <hr className="my-5"/>

        <AboutBio

          name="George"
          title=""
          bio1="Well-honed Senior Systems Engineer who thrives on the versatility of his role. George is actively involved in his community, dedicating his free time to coaching girls' softball, leading a Cub Scout Den, and pursuing a passion for crafting with wood and leather."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={RasheedJ}
          name="Rasheed"
          title=""
          bio1="Experienced Senior Software Engineer wielding the strengths of both JavaScript and Java to deliver robust solutions. A die-hard sports fan, enthusiastically cheering for the Jacksonville Jaguars and Tampa Bay Lightning."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={RobertT}
          name="Robert"
          title=""
          bio1="Partner & IT Architect. His expertise spans IT architecture, Java development, database development, and technical leadership. In his high school basketball career, he holds a unique claim to fame. During a summer practice session, Rob found himself on the receiving end of a dunk by none other than NBA legend David Robinson."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={BrimleyN}
          name="Brimley"
          title=""
          bio1="Senior Systems Engineer focusing on system administration. During his younger days, he had the ability to perform a skateboard backflip."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={CamillaN}
          name="Camilla"
          title=""
          bio1="Enthusiastic Web Developer who enjoys delving into code and understanding the intricacies of system operations. Her last name, Nadeau, translates to 'Christmas' in the Occitan language."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={BrianR}
          name="Brian"
          title=""
          bio1="IT Project Manager who enjoys using technology to improve lives. He also holds a state fishing record that was previously held by his father."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={SladeB}
          name="Slade"
          title=""
          bio1="Operations Administrator dedicated to learning and growing BNL’s processes. Outside of work, you can usually find him with a video game controller in hand."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={ThongN}
          name="Thong"
          title=""
          bio1="Senior SAS Developer with experience in data engineering. He’s also an avid World Traveler & Foodie who enjoys immersing himself in local experiences, savoring regional cuisine, and trying new drinks while taking in the sights."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={NicoleB}
          name="Nicole"
          title=""
          bio1="Creative and precise graphic designer. When not designing, you might find Nicole surrounded by her furry companions: Primrose and Lilly, her cats, and Persephone, her dog."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={CarlieR}
          name="Carlie"
          title=""
          bio1="As the Sales Operations Manager, Carlie specializes in creating and implementing organizational processes and protocols that streamline sales activities and drive operational efficiency. She has a keen eye for identifying opportunities and a strategic approach to opportunity capture, ensuring that sales teams are equipped with the necessary resources and support to succeed."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          name="Denys"
          title=""
          bio1="Skilled Data Engineer & BI Specialist. His expertise bridges the gap between data engineering and business intelligence, enabling organizations to make data-driven decisions. In his free time, he loves to read about history."
          bio2=""
        />
        <hr className="my-5"/>

        <AboutBio
          image={BradG}
          name="Brad"
          title=""
          bio1="As the Director of Implementation, Brad leads teams to deliver projects on time and within budget, exceeding strategic objectives. His passion lies in collaborating with others to find innovative solutions that streamline processes and simplify lives."
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={CourtneyH}
          name="Courtney"
          title=""
          bio1="BNL’s operations and recruiting intern, helping manage the flow of the recruiting process. During her downtime, she loves to sing!"
          bio2=""
        />

        <hr className="my-5"/>
        <AboutBio
          image={MaddieG}
          name="Maddie"
          title=""
          bio1="As the Communications Manager at BNL Consulting, she leads strategic messaging, brand storytelling, and public relations efforts. With a passion for crafting compelling narratives, Maddie ensures BNL’s mission and successes resonate across digital platforms, industry events, and client communications. Their expertise in content creation, media outreach, and brand strategy helps position BNL as a thought leader in the tech and data analytics space."
          bio2=""
        />

      </div>

      <div className="p-2 background-dark-blue d-flex justify-content-center">

        <div className="d-inline-block p-1 p-md-4">
          <img src={NCMABadge} alt="NCMA 2023 Badge" height={180}/>
        </div>

        <div className="d-inline-block p-3">
          <img src={ELEVBadge} alt="ELEV 2024 Badge" height={180}/>
        </div>

      </div>

      <ContactUs
        message="Are you interested in joining our team?"
        buttonText="Current Job Postings"
        href="https://www.indeed.com/cmp/Bnl-Consulting"
      />

    </div>
  );
}
