import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';

import GhostContentAPI from '@tryghost/content-api';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import LoadingIndicator from '../../components/Common/LoadingIndicator/LoadingIndicator';
import BlogOverview from '../../components/Blog/BlogOverview';
import BlogFeatureListCard from '../../components/Blog/BlogFeatureListCard';
import BlogCategoryListCard from '../../components/Blog/BlogCategoryListCard';
import BlogContactCard from '../../components/Blog/BlogContactCard';

import BlogHeroImage from '../../assets/images/heros/blog-hero.jpg';

import './Blog.css';
import BlogGhostSubscribe from '../../components/Blog/BlogGhostSubscribe';

const ghost = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_URL,
  key: process.env.REACT_APP_GHOST_KEY,
  version: 'v5'
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Blog() {
  const history = useHistory();
  const params = useQuery();

  const selectedCategory = params.get('category') || '';
  const page = params.get('page') || 1;

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // replace spaces in tag names with dash to prevent ghost api error
    const cleanCategory = selectedCategory?.replace(/ /g, '-');
    const filter = selectedCategory === '' ? undefined : `tags:[${cleanCategory}]`;

    // Reset content
    setLoading(true);
    setPosts([]);

    ghost.posts.browse({page: page, limit: 5, include: 'tags', filter: filter})
      .then(posts => setPosts(posts))
      .catch(() => setPosts([]))
      .finally(() => setLoading(false));

  }, [page, selectedCategory]);

  function updateQueryString(nextPage = page, category = selectedCategory) {
    const categoryString = category?.length ? `&category=${category}` : '';

    history.push({
      pathname: '/blog',
      search: `?page=${nextPage}${categoryString}`
    });
  }

  // Add category to meta title and description so they are unique
  const categorySuffix = selectedCategory ? ` - ${selectedCategory}` : ''
  const title = 'Blog' + categorySuffix
  const description = "Advice, news, and assorted musings from our team" + categorySuffix

  return (
    <div className="Blog">

      <MetaData
        title={title}
        description={description}
        image={BlogHeroImage}
      />

      <Hero
        title="BLOG"
        detail="Advice, news, and assorted musings from our team"
        image={BlogHeroImage}
      />

      <div className="container">
        <div className="row pt-lg-3">

          <div className="col-lg-7 col-xl-8 pt-5 pb-3">

            <LoadingIndicator className="pt-5" loading={loading}/>

            {!loading && posts?.length < 1 && (
              <div className="text-center text-muted pt-3">
                <h3>
                  No Matching Articles Found
                </h3>
                <Link to="/blog">
                  <button className="btn btn-outline-dark mt-3">
                    Reset Filters
                  </button>
                </Link>
              </div>
            )}

            {posts?.map(post => (
              <BlogOverview key={post.id} post={post}/>
            ))}

            {posts?.meta?.pagination?.prev && (
              <button className="btn btn-dark float-left mb-5 mt-3"
                      onClick={() => updateQueryString(posts.meta.pagination.prev)}>
                <span>&larr;</span>&nbsp; Newer Posts
              </button>
            )}

            {posts?.meta?.pagination?.next && (
              <button className="btn btn-dark float-right mb-5 mt-3"
                      onClick={() => updateQueryString(posts.meta.pagination.next)}>
                Older Posts &nbsp;<span>&rarr;</span>
              </button>
            )}

          </div>

          <div className="col-lg-5 col-xl-4 d-none d-lg-block pt-5 pb-3">

            <BlogGhostSubscribe />

            <BlogCategoryListCard className="mb-4" selected={selectedCategory}/>

            <BlogFeatureListCard className="mb-4"/>

            <BlogContactCard className="mb-4"/>

          </div>

        </div>
      </div>
    </div>
  );
}
