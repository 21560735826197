import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

import GhostContentAPI from '@tryghost/content-api';

import FormattedDate from '../../components/Common/FormattedDate/FormattedDate';
import MetaData from '../../components/Common/MetaData/MetaData';
import SocialShareButton from '../../components/Common/SocialButton/SocialShareButton';
import LoadingIndicator from '../../components/Common/LoadingIndicator/LoadingIndicator';
import BlogFeatureCards from '../../components/Blog/BlogFeatureCards';
import BlogDisqus from '../../components/Blog/BlogDisqus';
import BlogHtmlBody from '../../components/Blog/BlogHtmlBody';

import placeholderImage from '../../assets/images/misc/placeholder.png';

import './BlogDetail.css';

const ghost = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_URL,
  key: process.env.REACT_APP_GHOST_KEY,
  version: 'v5'
});

export default function BlogDetail() {
  // Blog key is slug from url
  const slug = useParams()?.slug;

  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(true);
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);

  useEffect(() => {
    setLoading(true);
    setBlog({});

    ghost.posts.read({slug, include: 'authors,tags'}, {formats: ['html', 'plaintext']})
      .then(blog => setBlog(blog))
      .catch(() => setBlog({}))
      .finally(() => setLoading(false));
  }, [slug]);

  return (
    <div className="BlogDetail">

      <MetaData
        title={blog.title || 'Blog'}
        description={blog.excerpt}
        absoluteImage={blog.feature_image}
      />

      <div>
        <div className="blog-background-image">
          <img className="img-responsive img-center" src={placeholderImage} alt="loading"/>
        </div>

        <div className="blog-detail-image">
          <img className={`img-blog ${heroImageLoaded && 'loaded'}`} alt={blog.title}
               onLoad={() => setHeroImageLoaded(true)}
               src={blog.feature_image}/>
        </div>

        <div className="blog-darken-gradient"/>
      </div>

      <LoadingIndicator className="py-5 min-vh-100" loading={loading}/>

      {!loading && (
        <div className="container py-4 py-md-5">

          <div className="row">
            <div className="col col-12 offset-0 col-md-8 offset-md-2">
              <h1 className="h3 pt-1 pt-md-3 mb-2">
                {blog.title}
              </h1>
              <p className="font-weight-light pb-1 pb-md-3 text-muted">
                Posted by {blog.primary_author?.name} on <FormattedDate date={blog.published_at}/>
              </p>
            </div>
          </div>

          <div className="row">

            <div className="col col-md-8 offset-0 offset-md-2">

              <BlogHtmlBody className="body mb-5" post={blog}/>

              <BlogFeatureCards className="py-2"/>

              <BlogDisqus className="py-2" post={blog}/>

            </div>

            <div className="col-12 col-md-2 pl-md-4 pb-3 order-first order-md-last">

              <div>
                {blog.tags?.map(tag => (
                  <div key={tag.name} className="mr-1 mr-md-0 mb-1 d-inline-block d-md-block">
                    <Link key={tag.name} to={`/blog?category=${tag.name}`}>
                      <div className="badge background-gray text-capitalize">
                        {tag.name}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>

              <div className="mt-md-4">
                <div className="mr-1 mr-md-0 mb-1 d-inline-block d-md-block">
                  <SocialShareButton className="badge" type="twitter"/>
                </div>
                <div className="mr-1 mr-md-0 mb-1 d-inline-block d-md-block">
                  <SocialShareButton className="badge" type="linkedin"/>
                </div>
                <div className="mr-1 mr-md-0 mb-1 d-inline-block d-md-block">
                  <SocialShareButton className="badge" type="facebook"/>
                </div>
                <div className="mr-1 mr-md-0 mb-1 d-inline-block d-md-block">
                  <SocialShareButton className="badge" type="reddit"/>
                </div>
                <div className="mr-1 mr-md-0 mb-1 d-inline-block d-md-block">
                  <SocialShareButton className="badge" type="pinterest" media={blog.feature_image}/>
                </div>
              </div>

            </div>

          </div>
        </div>
      )}

    </div>
  );
}
