import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import CaseStudyDivider from '../../components/CaseStudies/CaseStudyDivider';
import CaseStudySection from '../../components/CaseStudies/CaseStudySection';

import WorldAwareBanner from '../../assets/images/usda/usda-banner.jpg';


export default function Usda() {
  return (
    <div className="CdcWisqars">

      <MetaData
        title="USDA Case Study"
        description="Technical Services Surge Support
for the National Finance Center"
        image={WorldAwareBanner}
      />

      <div>
        <img
          className="responsive-image w-100 border-bottom-banner"
          alt="USDA"
          src={WorldAwareBanner}
        />
      </div>

      <div className="container">

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">USDA: Technical Services Surge Support
            for the National Finance Center</h1>
        </div>

        <CaseStudyDivider>Overview</CaseStudyDivider>

        <CaseStudySection>
          The National Finance Center (NFC) is the USDA’s Shared Service Provider for Financial Management Services and
          Human Resources Management Services like payroll, budgeting, and financial reporting. They assist agencies in
          achieving cost-effective, standardized, and interoperable solutions that provide functionality to support strategic
          financial management and human resource management direction. NFC’s service offerings are designed to
          eliminate time-consuming, transactional functions and increase time for agencies to perform mission activities.
          Essentially, the NFC’s high-level development needs had exceeded their internal capabilities. In order to enact
          much needed changes and upgrades, they required external expertise.
        </CaseStudySection>

        <CaseStudyDivider>Challenge</CaseStudyDivider>

        <CaseStudySection>
          <p>
            The USDA NFC faced several onboarding challenges, starting with a technical expertise gap. The organization has many critical priorities and lacked bandwidth for high-end developers with the necessary
            skills to manage the proposed changes effectively. Onboarding new developers or training existing staff
            presented difficulties due to the complexity of the tasks and the specialized skills required. Additionally,
            resource allocation was a significant challenge. Ensuring the development team had adequate support
            and infrastructure to complete the project within the specified timeline required careful management
            of human resources, budget constraints, and procurement processes.
          </p>
          <p>
            Issues with credentials also posed obstacles, particularly in providing developers with access to the
            development environment and the necessary tools and software for web development and mainframe
            programming. Ensuring developers had the appropriate permissions and configurations was critical
            for efficient work.
          </p>
          <p>
            In terms of software challenges, compatibility with legacy systems created significant hurdles. Integrating
            new web development solutions with existing legacy systems and mainframe infrastructure required
            thorough testing and debugging to ensure seamless communication and data exchange.
          </p>
          <p>
            Ensuring all web applications and interfaces adhered to 508 Compliance accessibility standards, particularly while maintaining a modern and intuitive user interface, demanded meticulous attention to detail.
          </p>
          <p>
            Testing and quality assurance processes were essential to the project’s success. Implementing comprehensive testing, including unit, integration, and user acceptance testing, was necessary to identify and
            resolve software bugs, performance issues, and compatibility problems, ensuring that final deliverables
            met client requirements and quality standards
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Process</CaseStudyDivider>

        <CaseStudySection>
              <p>
                Our strong partnership with the USDA’s National Finance Center (NFC) resulted in a transformative modernization of key systems and processes. Our team’s initiative focused on enhancing the efficiency, security, and
                user experience of several critical USDA applications, with the goal of streamlining operations and ensuring
                compliance with federal standards
              </p>
        </CaseStudySection>


            <CaseStudyDivider>Our Solution</CaseStudyDivider>

            <CaseStudySection>
              <ul>
                <li>
              <p>
                We took on the challenge of modernizing the CLER application, which manages essential payroll and personnel data at the USDA NFC. The outdated Classic ASP architecture was replaced with a more robust and
                scalable solution using React for the front-end and ASP.NET Core for the back-end. The new Single Page
                Application (SPA) model offers faster load times, improved user experience, and enhanced security. By
                integrating automated scheduling, a significant pain point for end users, USDA staff can now update over
                3,000 schedules annually with a single click, reducing processing time by up to 30 times and significantly
                improving operational efficiency.
              </p>
            </li>
            <li>
              <p>
                Previously, generating the Treasury Report
                on Receivables (TROR) was a labor-intensive
                process that took up to three weeks. Our team
                introduced an automated solution that batch
                runs reports, consolidates data, and performs
                complex calculations. This automation reduces the
                processing time to just six hours, ensuring timely
                and accurate data submissions while improving
                financial oversight and regulatory compliance.
              </p>
            </li>
            <li>
              <p>
                We also resolved long-standing issues within the
                DPRS (Document Production, Retrieval, and Storage) system, particularly with the generation of
                Federal Employee Health Benefits (FEHB) letters.
                Synchronizing multiple programs and eliminating errors that led to reprinting letters has resulted in a smoother,
                more efficient process. These improvements, addressing issues that had persisted for 17 years, garnered high
                praise from USDA staff and positioned BNL for future projects.
              </p>
            </li>
          </ul>
        </CaseStudySection>

        <CaseStudyDivider>Results</CaseStudyDivider>

        <CaseStudySection>
          <p>
            Through these efforts, we not only modernized critical USDA systems but also created a more flexible and
            scalable technological landscape. The USDA NFC can now operate with greater efficiency, accuracy, and
            security, setting the stage for continued innovation and success in the future.
          </p>
        </CaseStudySection>

      </div>

      <ContactUs>
        Want to see what we can build for you?
      </ContactUs>

    </div>
  );
}
