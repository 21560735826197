import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import DataAnalyticsHeroImage from '../../assets/images/heros/data-analysis-hero.png';

import DataBricksIcon from '../../assets/images/databricks/databricks.jpg';
import DataBricksChart from '../../assets/images/databricks/databricks-charts.png';
import DataBricksExperiments from '../../assets/images/databricks/databricks-experiments.png';
import DataBricksScreen from '../../assets/images/databricks/databricks-screen.png';
import DataBricksPlayground from '../../assets/images/databricks/databricks-playground.png';

export default function Databricks() {
  return (
    <div className="Databricks">

      <MetaData
        title="Data Analytics Solutions for Actionable Intelligence"
        description="We stay ahead of cutting-edge data analytics methodologies to provide better outcomes
        for your big data. Get your free data solutions consultation today."
        image={DataAnalyticsHeroImage}
      />

      <Hero
        image={DataAnalyticsHeroImage}
        title="Databricks Consulting"
        detail=""
        reducedFont={true}
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          Databricks has revolutionized the way organizations harness the power of data to drive business outcomes.
          Their unique combination of a future-proof solution combined with enhanced collaboration provides cost savings
          in areas like infrastructure and resource utilization while preparing your organization for the next big
          thing.
        </p>
        <p>
          We’re proud to choose DataBricks as one of our core data visualization partners, enabling our valued clients
          with state-of-the-art solutions for their data needs. Here’s what the partnership brings to the table:
        </p>


        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">

            <h2 className="h3">
              Transform Your Data Visualization
            </h2>

            <h3 className='h5'>Accelerated Time-to-Value:</h3>
            <p>With Databricks, our customers can accelerate their time-to-insight and time-to-market for data-driven
            initiatives, gaining a competitive edge in their industry.</p>

            <h3 className='h5'>Enhanced Collaboration:</h3>
            <p>Databricks fosters collaboration and knowledge sharing across data engineering, data science, and business
            teams, driving cross-functional alignment and innovation.</p>

            <h3 className='h5'>Cost Savings:</h3>
            <p>By leveraging Databricks' cloud-native architecture, our customers can reduce infrastructure costs and
            optimize resource utilization, delivering tangible cost savings over traditional on-premises solutions.</p>

            <h3 className='h5'>Future-Proofing:</h3>
            <p>Databricks is at the forefront of innovation in the data and AI space, continuously adding new features and
            capabilities to its platform. Our partnership ensures that our customers stay ahead of the curve and
              future-proof their data infrastructure.</p>
            <h3 className='h5'>Expert Support and Services:</h3>
            <p>As a trusted Databricks partner, we provide expert guidance, support, and consulting services to help our
            customers maximize the value of their investment in Databricks.</p>
            <p>Their commitment to advancing the way we utilize data and equipping our clients for success in the face of
              even the most complex data sets makes them a perfect match for our clients’ needs.</p>
          </div>
          <div className="col-lg-5 col-sm-12">

            <img className="responsive-image mt-5"
                 alt="DataBricks Chart Screenshot"
                 src={DataBricksChart}/>

            <img className="responsive-image mt-5"
                 alt="DataBricks Screenshot"
                 src={DataBricksScreen}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-5 col-sm-12 order-sm-last">
            <img className="responsive-image"
                 alt="DataBricks Experiments Screenshot"
                 src={DataBricksExperiments}/>

            <img className="responsive-image mt-5"
                 alt="DataBricks Screenshot"
                 src={DataBricksPlayground}/>

          </div>

          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-first ">
            <h3>
              Changing the Data Game
            </h3>
            <h5>Unified Analytics Platform:</h5>
            <p>
              Transition your existing workloads to Azure with little to no downtime. Our certified migration experts
              ensure a smooth and secure shift to the cloud.
            </p>
            <h5>Infrastructure as a Service (IaaS)</h5>
            <p>
              Take full advantage of virtual machines, storage, and networks with Azure IaaS. Scale your infrastructure
              on demand without the need for physical hardware.
            </p>
            <h5>Platform as a Service (PaaS)</h5>
            <p>
              Develop, deploy, and manage applications without worrying about the underlying infrastructure. Azure PaaS
              enables rapid development and innovation.
            </p>
            <h5>Azure DevOps</h5>
            <p>
              Automate your software delivery pipeline with Azure DevOps. With integrated tools and CI/CD pipelines, you
              can collaborate efficiently and release new features faster.
            </p>
            <h5>Data and Analytics</h5>
            <p>
              Unlock insights from your data with Azure's powerful analytics and AI services. Transform raw data into
              actionable insights with Azure Synapse, Power BI, and more.
            </p>
            <h5>Security and Compliance</h5>
            <p>
              Keep your data and applications secure with Azure's advanced security features. Our experts help you
              navigate compliance requirements and protect your assets.
            </p>
          </div>


        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-8 col-sm-12  offset-lg-2">
            <h3>
              Get Started with Azure
            </h3>
            <p>
              Ready to embark on your data journey with Databricks? Contact us <a href='http://bnlconsulting.com/contact'>here</a> to learn more about how our partnership can accelerate your path to data innovation.
            </p>
          </div>

        </div>

        <div className="p-5"/>

      </div>

      <ContactUs>
        Looking to turn your data into actionable intelligence?
      </ContactUs>

    </div>
  );
}
