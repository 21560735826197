import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import DataAnalyticsHeroImage from '../../assets/images/heros/data-analysis-hero.png';
import AzureIcon from '../../assets/images/azure/azure.jpg';
import AzureMigrate from '../../assets/images/azure/azure-migrate.png';
import AzureResources from '../../assets/images/azure/azure-resources.png';
import AzureOverview from '../../assets/images/azure/azure-overview.png';

export default function MicrosoftAzure() {
  return (
    <div className="MicrosoftAzure">

      <MetaData
        title="Data Analytics Solutions for Actionable Intelligence"
        description="We stay ahead of cutting-edge data analytics methodologies to provide better outcomes
        for your big data. Get your free data solutions consultation today."
        image={DataAnalyticsHeroImage}
      />

      <Hero
        image={DataAnalyticsHeroImage}
        title="Azure Services"
        detail="Unlock the Power of the Cloud with Azure"
        reducedFont={true}
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          Microsoft Azure Cloud Services offers a comprehensive suite of cloud computing services to help your business
          scale, innovate, and stay secure. Whether you're looking to build, deploy, or manage applications in the
          cloud, Azure provides the tools and resources to support your journey.
        </p>


        <hr className="my-5"/>

        <div className="row">

            <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h2 className="h3">
              Why Choose Microsoft Azure?
            </h2>
            <ul>
              <li><strong>Global Reach:</strong> Operate on a global scale with data centers in more than 60 regions
                worldwide.
              </li>
              <li><strong>Security & Compliance:</strong> Enterprise-grade security with more certifications than any
                other cloud
                provider.
              </li>
              <li><strong>Flexibility:</strong> Seamlessly integrate with your existing infrastructure and on-premises
                solutions.
              </li>
              <li><strong>Cost-Effective:</strong> Only pay for what you use, with flexible pricing options that fit
                your business
                needs.
              </li>
              <li><strong>Innovation:</strong> Leverage cutting-edge AI, machine learning, and IoT services to drive
                business growth.
              </li>
            </ul>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="BNL Google Flu Trends Data Visualization"
                 src={AzureIcon}/>

          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-5 col-sm-12 order-sm-last">
            <img className="responsive-image"
                 alt="Azure Migrate Screenshot"
                 src={AzureMigrate}/>

            <img className="responsive-image mt-5"
                 alt="Azure Overview Screenshot"
                 src={AzureOverview}/>

            <img className="responsive-image mt-5"
                 alt="Azure Overview Resources"
                 src={AzureResources}/>

          </div>


          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-first ">
            <h3>
              Our Azure Cloud Services
            </h3>
            <h5>Cloud Migration</h5>
            <p>
              Transition your existing workloads to Azure with little to no downtime. Our certified migration experts
              ensure a smooth and secure shift to the cloud.
            </p>
            <h5>Infrastructure as a Service (IaaS)</h5>
            <p>
              Take full advantage of virtual machines, storage, and networks with Azure IaaS. Scale your infrastructure
              on demand without the need for physical hardware.
            </p>
            <h5>Platform as a Service (PaaS)</h5>
            <p>
              Develop, deploy, and manage applications without worrying about the underlying infrastructure. Azure PaaS
              enables rapid development and innovation.
            </p>
            <h5>Azure DevOps</h5>
            <p>
              Automate your software delivery pipeline with Azure DevOps. With integrated tools and CI/CD pipelines, you
              can collaborate efficiently and release new features faster.
            </p>
            <h5>Data and Analytics</h5>
            <p>
              Unlock insights from your data with Azure's powerful analytics and AI services. Transform raw data into
              actionable insights with Azure Synapse, Power BI, and more.
            </p>
            <h5>Security and Compliance</h5>
            <p>
              Keep your data and applications secure with Azure's advanced security features. Our experts help you
              navigate compliance requirements and protect your assets.
            </p>
          </div>

        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-8 col-sm-12  offset-lg-2">
            <h3>
              Get Started with Azure
            </h3>
            <p>
              Ready to transform your business with Microsoft Azure? Our team of certified Azure experts is here to
              guide you every step of the way.
            </p>
              <a href='http://bnlconsulting.com/contact'>Contact Us</a> for a free consultation<br />
              <a href='http://bnlconsulting.com/contact'>Request a Demo</a> to see Azure in action<br />
              <a href='http://bnlconsulting.com/contact'>Learn More</a> about our Azure services

          </div>

        </div>

        <div className="p-5"/>

      </div>

      <ContactUs>
        Ready to Unlock the Power of the Cloud?
      </ContactUs>

    </div>
  );
}
