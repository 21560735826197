import React from 'react';

import BrowserWork from '../../assets/images/intro/Browser-Work.png';
import MobileDevices from '../../assets/images/intro/Mobile-Devices.png';
import BrowserAnalytics from '../../assets/images/intro/Browser-Analytics.png';

const CORPORATE_PROFILE = process.env.PUBLIC_URL + '/bnl-company-profile-12_23.pdf';

export default function HomeServices() {
  return (
    <div className="container">
      <div className="row my-5">

        <div className="col-lg-4 text-center p-4">
          <img
            className="img-fluid rounded-circle px-5 px-md-4 w-75"
            alt="Data analytics services"
            src={BrowserAnalytics}
          />

          <h3 className="my-4">
            Analytics
          </h3>

          <p className="mb-4">
            The days of sifting through mountains of data are over. Our solutions deliver actionable insights instantly,
            with data analysis speeds improved by up to 30% and a user-friendly interface that simplifies
            decision-making. Leveraging cutting-edge tools like SAS Viya, SAS 9.4, and Python, we enable your
            organization to unlock insights that increase efficiency and drive outcomes.
          </p>

        </div>


        <div className="col-lg-4 text-center p-4">
          <img
            className="img-fluid rounded-circle px-5 px-md-4 w-75"
            alt="Applications development visualization"
            src={MobileDevices}
          />

          <h3 className="my-4">
            Applications
          </h3>

          <p className="mb-4">
            We design customized, highly interactive data analytics applications tailored to your enterprise’s unique
            needs. Whether creating mobile solutions or multi-platform systems, our applications empower stakeholders to
            make data-driven decisions 40% faster. With 90% user adoption rates, these solutions adapt to your evolving
            business challenges and ensure all stakeholders have the insights they need when they need them.
          </p>

        </div>


        <div className="col-lg-4 text-center p-4">
          <img
            className="img-fluid rounded-circle px-5 px-md-4 w-75"
            alt="Enterprise Integration Services"
            src={BrowserWork}
          />

          <h3 className="my-4">
            Architecture
          </h3>

          <p className="mb-4">
            Our cloud-based architectures reduce risks and costs by up to 25%, transforming legacy systems into modern,
            scalable solutions. Partnering with industry leaders like AWS and Azure, we deliver architectures that
            integrate seamlessly with your existing analytic systems while improving performance and scalability by over
            35%. This approach ensures your organization is ready for the demands of today—and tomorrow.
          </p>

        </div>

      </div>

      <div className="row">
        <div className="col-lg-6 mx-auto">
          <a href={CORPORATE_PROFILE} className="btn btn-primary btn-block mx-auto" target="_blank" rel="noreferrer">
            Enable your organization with analytics expertise.
          </a>
        </div>
      </div>
    </div>
  );
}
