import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import GhostContentAPI from '@tryghost/content-api';
import {Link} from 'react-router-dom';

import LoadingIndicator from '../Common/LoadingIndicator/LoadingIndicator';

const ghost = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_URL,
  key: process.env.REACT_APP_GHOST_KEY,
  version: 'v5'
});

function BlogFeatureListCard(props) {
  const {className} = props;

  const [loading, setLoading] = useState(true);
  const [featuredPosts, setFeaturedPosts] = useState([]);

  useEffect(() => {
    ghost.posts.browse({limit: 5, filter: 'featured:true'})
      .then(result => setFeaturedPosts(result))
      .catch(() => setFeaturedPosts([]))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={className}>
      <div className="card">
        <div className="card-body">

          <h3 className="h5 card-title">
            Featured Articles
          </h3>

          <div className="card-text">
            <LoadingIndicator className="my-5" loading={loading} dimension={50}/>

            {featuredPosts.map(post => (
              <div className="mb-3" key={post.id}>
                <Link to={`/blog/${post.slug}`}>
                  <strong>{post.title}</strong>
                </Link>
                <br/>
                {post.excerpt}
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
}

BlogFeatureListCard.propTypes = {
  className: PropTypes.string
};

export default BlogFeatureListCard;
