import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import AboutBio from '../../components/About/AboutBio';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import AboutHero from '../../assets/images/heros/about-us-hero.png';
import RobertLill from '../../assets/images/headshots/Robert-Lill.jpg';
import MichaelArnett from '../../assets/images/headshots/Michael-Arnett.jpg';
import RobertTabit from '../../assets/images/headshots/Robert-Tabit.jpg';
import AmyArnett from '../../assets/images/headshots/Amy-Arnett.jpg';
import BnlTeam from '../../assets/images/misc/bnl-about-team.jpg';

import NCMABadge from '../../assets/images/partners/NCMA_Badge.png';
import ELEVBadge from '../../assets/images/partners/ELEV_Badge.png';

export default function About() {

  return (
    <div className="About">

      <MetaData
        title="About Us"
        description="The story and leadership team or BNL Consulting"
        image={AboutHero}
      />

      <Hero
        image={AboutHero}
        title="ABOUT"
        detail="Get to know our company and its story"
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          As industry leaders in data analytics, data visualization, and enterprise integration, we're proud to share
          the story of our company and the individuals who comprise our core leadership team.
        </p>

        <h2 className="h3">
          Our Mission
        </h2>
        <p>
          BNL Consulting utilizes our back-end data orchestration and front-end, human-centered design expertise to
          deliver accessible and custom solutions to government and commercial clients, bringing their data analytics,
          data visualization, enterprise integration, and modernization projects to life.
        </p>

        <h2 className="h3">Vision</h2>
        <p>
          We seek to empower the world with valuable data and beautifully crafted, user-centered dashboards that tell
          the
          complete stories behind data in a way that feels approachable for each and every end user.
        </p>

        <h2 className="h3">Values</h2>
        <p>
          Here at BNL, we foster an inclusive environment where our employees and their unique, individual talents can
          thrive. We’re passionate about building up the communities we operate in and leaving places and people better
          for having worked with us.
        </p>
        <img src={BnlTeam} alt='BNL team dinner' className='img-fluid rounded mx-auto d-block px-3 pt-3 w-75' />


        <hr className="my-5"/>

        <AboutBio
          image={RobertLill}
          name="Robert Lill"
          title="President & Chief Architect"
          bio1="Rob has over twenty-five years of experience working with big data and
            analytics using best-in-class technologies and best practice based implementations.
            Rob has served as the lead architect on custom solutions for several of BNL’s
            clients, including: the Department of Health and Human Services (HHS), the Food and
            Drug Administration (FDA), the Centers for Disease Control and Prevention (CDC), and
            the United States Census Bureau. Rob’s reputation as a strong architect and a focused
            leader has helped BNL Consulting become one of the top providers of professional services
            to its clients."
          bio2="Prior to BNL, Rob spent 8 years at the SAS Institute as a Technical Architect and
            Systems Engineer. He has over two decades of experience in application development, with a
            strong expertise in health care data analysis and reporting. Rob is well-versed in
            several different programming languages and holds a degree in Political Science from The
            University of North Carolina at Wilmington."
          linkedin="https://www.linkedin.com/in/roblill"
        />

        <hr className="my-5"/>

        <AboutBio
          image={MichaelArnett}
          name="Michael Arnett"
          title="CEO & Senior Systems Architect"
          bio1="Mike’s career has spanned more than twenty years of software development. Over that time,
            he has crafted solutions using a broad range of languages and technologies, but specializes in
            Enterprise Java solutions and implementations. Mike provides technical guidance and assists in
            the successful delivery of enterprise systems for both corporate and public sector clients. He
            focuses on identifying creative solutions for integration and implementation issues (such as security
            and privacy concerns) commonly occurring in large enterprise systems comprised of heterogeneous
            technologies and constrained by inflexible business realities."
          bio2="Mike joined BNL Consulting in 2008 after working as a contractor for several years at
            the Centers for Disease Control and Prevention (CDC) on the Lab Response Network’s BioWatch
            initiative. Additionally, Mike is credited as one of the co-inventors on a system patent held by the
            Health Care Incentives Improvement Initiative. Mike holds a Bachelor of Arts in Computer Science
            from the University of Georgia."
          linkedin="https://www.linkedin.com/in/miarnett"
        />

        <hr className="my-5"/>

        <AboutBio
          image={RobertTabit}
          name="Robert Tabit"
          title="Director of Enterprise Development"
          bio1="Robert has more than twenty years of experience building Enterprise Java solutions in a
            diverse set of verticals including government, e-commerce, agriculture, law enforcement, and
            transportation. As the Director of Enterprise Development, Robert currently focuses on leading BNL’s
            internal product development efforts while also acting as the technical lead for BNL’s U.S. Census
            Bureau projects. He is involved with building commercial software products that complement BNL’s
            leadership in data analytics, data visualization, and enterprise integration."
          bio2="Robert joined BNL Consulting in 2011 after an extended career at IBM where he worked in
            both software consulting and software sales with an emphasis on Enterprise Content Management and
            Enterprise Java Development. He is also credited as a co-inventor on a patent (US7962159) held by
            IBM which details a method and system for receiving data on portable devices. Robert holds a B.S.
            in Computer Science and a M.S. in Computer Systems Management, both from the University of Maryland."
          linkedin="https://www.linkedin.com/in/robert-tabit-88a25a53"
        />

        <hr className="my-5"/>

        <AboutBio
          image={AmyArnett}
          name="Amy Arnett"
          title="Director of Sales and Business Development"
          bio1={(
            <>
              With nearly a decade of Sales Development under her belt, Amy Arnett leads her team from one
              exciting opportunity to the next. Working closely with agencies like
              <a href="https://www.hhs.gov/" target="_blank" rel="noreferrer"> The Department of Health and Human Services</a>,
              <a href="https://www.census.gov/" target="_blank" rel="noreferrer"> The US Census Bureau</a>, and more, Amy goes out of her way
              to ensure our clients get the results they need.
            </>
          )}
          linkedin="https://www.linkedin.com/in/amy-a-2079a3104"
        />



      </div>

      <div className="p-2 background-dark-blue d-flex justify-content-center">

          <div className="d-inline-block p-1 p-md-4">
            <img src={NCMABadge} alt="NCMA 2023 Badge" height={180}/>
          </div>

          <div className="d-inline-block p-3">
            <img src={ELEVBadge} alt="ELEV 2024 Badge" height={180}/>
          </div>

      </div>

      <ContactUs
        message="Are you interested in joining our team?"
        buttonText="Current Job Postings"
        href="https://www.indeed.com/cmp/Bnl-Consulting"
      />

    </div>
  );
}
