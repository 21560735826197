import React, {useEffect, useState, useRef} from 'react';

import {useLocation} from 'react-router-dom';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';

import NavLinkActive from './NavLinkActive';
import NavDropdownActive from './NavDropdownActive';
import NavDropdownItemActive from './NavDropdownItemActive';

import BnlLogo from '../../../assets/images/bnl-logo.svg';

import './NavBar.css';

const SERVICES_PATHS = ['/data-analytics', '/data-visualization', '/enterprise-integration', '/sas-consulting',
  '/cloud-services', '/system-modernization', '/project-management'];

const COMPANY_PATHS = ['/blog', '/about', '/bnl-living', '/environmental-policy','/cyclops', '/gsa'];

function NavBar() {
  const navRef = useRef(null);
  const pathname = useLocation()?.pathname;

  const [expanded, setExpanded] = useState(false);

  const shouldCollapse = () => window.scrollY > 100;
  const [collapsed, setCollapsed] = useState(shouldCollapse());
  const checkScrollPosition = () => setCollapsed(shouldCollapse());

  const className = 'NavBar ' + (collapsed ? 'collapsed' : '');

  // Close Mobile Nav when path changes
  useEffect(() => {
    setExpanded(false);
  }, [pathname]);

  // Collapse / Expand desktop nav with scroll
  useEffect(() => {
    checkScrollPosition();
    window.addEventListener('scroll', checkScrollPosition);
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  });

  // Close Mobile Nav if click outside
  useEffect(() => {
    const closeIfClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    window.addEventListener('click', closeIfClickOutside);
    return () => {
      window.removeEventListener('click', closeIfClickOutside);
    };
  }, [navRef]);

  return (
    <Navbar ref={navRef} expand="md" fixed="top" variant="dark"
            className={className} expanded={expanded} onToggle={setExpanded}>

      <Container>

        <Navbar.Brand href="/">
          <img src={BnlLogo} alt="BNL Consulting"/>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>

        <Navbar.Collapse id="responsive-navbar-nav" onSelect={() => setExpanded(false)}>
          <Nav className="ml-auto">
            <NavLinkActive to="/portfolio" display="PORTFOLIO"/>
            <NavDropdownActive display="SERVICES" activePaths={SERVICES_PATHS} id="services">
              <NavDropdownItemActive to="/microsoft-azure" display="Azure Services"/>
              <NavDropdownItemActive to="/data-analytics" display="Data Analytics"/>
              <NavDropdownItemActive to="/databricks" display="DataBricks Consulting"/>
              <NavDropdownItemActive to="/data-visualization" display="Data Visualization"/>
              <NavDropdownItemActive to="/enterprise-integration" display="Enterprise Integration"/>
              <NavDropdownItemActive to="/system-modernization" display="System Modernization"/>
              <NavDropdownItemActive to="/cloud-services" display="Cloud Services"/>
              <NavDropdownItemActive to="/sas-consulting" display="SAS Consulting"/>
              <NavDropdownItemActive to="/government" display="Government Contracting"/>
              {/*<NavDropdownItemActive to="/project-management" display="Project Management"/>*/}
            </NavDropdownActive>
            <NavDropdownActive display="OUR COMPANY" activePaths={COMPANY_PATHS} id="company">
              <NavDropdownItemActive to="/blog" display="Blog"/>
              <NavDropdownItemActive to="/about" display="About Us"/>
              <NavDropdownItemActive to="/team" display="Our Team"  />
              <NavDropdownItemActive to="/bnl-living" display="BNL Living"/>
              <NavDropdownItemActive to="/environmental-policy" display="Environmental Policy"/>
              <NavDropdownItemActive to="/cyclops" display="Cyclops Scheduler"/>
              <NavDropdown.Item href="https://www.indeed.com/cmp/Bnl-Consulting/jobs" children="Careers"/>
            </NavDropdownActive>
            <NavLinkActive to="/contact" display="CONTACT"/>
          </Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
}

export default NavBar;
