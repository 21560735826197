import React from 'react';

import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons';

import CapitolIcon from '../../assets/images/intro/Capitol-Icon.png';
import PulseIcon from '../../assets/images/intro/Pulse-Icon.png';
import PlayIcon from '../../assets/images/intro/Play-Icon.png';
import EnterpriseIntegrationIcon from '../../assets/images/intro/Enterprise-Integration-Icon.png';
import DataVisualization from '../../assets/images/intro/Data-Visualization-Icon.png';
import DataAnalytics from '../../assets/images/intro/Data-Analytics-Icon.png';
import EnterpriseBridge from '../../assets/images/intro/Enterprise-Bridge.png';

export default function HomeFeatures() {
  return (
    <div>

      <div className="background-light-gray">
        <div className="container">
          <div className="row">

            <div className="col-md-4 col-lg-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Data Analytics Graphic"
                src={DataAnalytics}
              />
            </div>

            <div className="col-md-8 col-lg-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Data Analytics
              </h3>
              <p>
                We unlock the power of your data by transforming it into actionable insights that drive results. Using tools like SAS, Python, and R, we’ve helped clients uncover critical trends, achieving 25% faster decision-making and reducing data processing time by 40%. Whether solving complex business problems or identifying new opportunities, our advanced analytics techniques provide the clarity and direction you need to succeed.
              </p>
              <Link to="/data-analytics">
                Data Analytics Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2 order-md-last">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Data Visualization Graphic"
                src={DataVisualization}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Data Visualization
              </h3>
              <p>
                Using tools like Power BI, Tableau, and D3, we craft compelling visuals that bring your data to life. Our custom visualizations and interactive dashboards provide a clear, actionable view of your information, empowering organizations to make data-driven decisions with ease. Designed with a user-first approach, these intuitive representations enable faster understanding and smarter decision-making. Our solutions have boosted client efficiency by up to 50%, cutting down manual reporting time and allowing leaders to make 80% of critical decisions in real-time.
              </p>
              <Link to="/data-visualization">
                Data Visualization Services &nbsp; <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="background-light-gray">
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Enterprise Integration Graphic"
                src={EnterpriseBridge}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Enterprise Integration
              </h3>
              <p>
                We unify IT subsystems using cloud-based solutions like AWS and Azure, ensuring seamless operational harmony across your organization. By integrating cutting-edge database systems and reporting tools, we’ve helped clients reduce operational silos by 35% and improve data accessibility across departments. Our visually rich applications and analytics empower teams with the insights they need, enhancing productivity and enabling data-driven decisions 90% faster.
              </p>
              <Link to="/enterprise-integration">
                Enterprise Integration Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="background-dark-blue">
        <div className="container">
          <div className="row py-5">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2 order-md-last">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL SAS Administration Graphic"
                src={CapitolIcon}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                SAS Consulting
              </h3>
              <p>
                Our SAS-certified experts specialize in scaling platforms, modernizing legacy systems, and migrating to open-source tools like Python, R, or platforms like Snowflake and DataRobot. So whether it’s installation, development, or system upgrades, we handle all aspects of SAS with unmatched expertise. As long-time SAS Partners, we’ve delivered measurable results, including improving system efficiency by 35% and reducing code migration time by 20%.
              </p>
              <Link to="/sas-consulting">
                SAS Consulting Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="background-light-gray">
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Cloud Services Graphic"
                src={EnterpriseIntegrationIcon}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Cloud Services
              </h3>
              <p>
                With over a decade of experience, our AWS-certified cloud consultants have led dozens of successful cloud migrations, saving clients 20% on infrastructure costs and increasing system scalability by 40%. Whether modernizing environments, ensuring compliance, or optimizing for security, we deliver robust solutions tailored to your organization. From AWS to Azure, we guarantee seamless transitions that enhance performance and reliability.
              </p>
              <Link to="/cloud-services">
                AWS Consulting Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2 order-md-last">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL System Modernization Graphic"
                src={PulseIcon}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                System Modernization
              </h3>
              <p>
                Our modernization solutions have transformed legacy systems for clients, achieving 40% cost savings and increasing system reliability by 30%. Using tools like AWS, Azure, Docker, and Kubernetes, we assess, refactor, and migrate code to ensure your architecture meets modern demands. Whether migrating systems or creating entirely new configurations, we deliver innovative solutions tailored to your business goals.
              </p>
              <Link to="/system-modernization">
                System Modernization Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="background-light-gray">
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Project Management Graphic"
                src={PlayIcon}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Project Management
              </h3>
              <p>
                Our project management services have helped clients deliver projects 15% under budget and reduce timelines by 20%, thanks to our expertise in methodologies like SAFe Agile, Scrum, and Waterfall. With comprehensive planning, coordination, and resource management, we guide teams through every phase of the software development lifecycle, ensuring projects are executed efficiently and aligned with your vision.
              </p>
              {/*<Link to="/project-management">*/}
              {/*  Project Management Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>*/}
              {/*</Link>*/}
            </div>

          </div>
        </div>
      </div>

      

    </div>
  );
}
